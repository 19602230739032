/**
 * Use this to apply business rules on data input/output
 */
import { ConflictException, ExceptionBase, ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import {
  OrganisationGetDTO,
  OrganisationPostDTO,
  OrganisationPutDTO,
} from '@connected-core-system/utils/organisation-http-dto';
import { IOrganisationCreateRepository } from '../types/IOrganisationCreateRepository';
import organisationCreateRepoInstance from './OrganisationCreateRepository';

export class OrganisationCreateManager {
  constructor(private repo: IOrganisationCreateRepository) {}

  async createOrganisation(organisation: OrganisationPostDTO, file?: File | null): Promise<OrganisationGetDTO | null> {
    try {
      const formData = new FormData();
      formData.append('title', organisation.title);
      formData.append('domain', organisation.domain);
      if (organisation.vanityDomain) {
        formData.append('vanityDomain', organisation.vanityDomain);
      }
      if (file) {
        formData.append('file', file, file.name);
      }
      if(organisation.tenantId) {
        formData.append('tenantId', organisation.tenantId);
      }
      const res = await this.repo.createOrganisation(formData);
      return res.data;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      const businessException = error as ExceptionBase;
      if (businessException && businessException.code === ExceptionCode.Conflict) {
        throw new ConflictException(businessException.message);
      }
      return null;
    }
  }

  async updateOrganisation(
    organisation: Partial<OrganisationPutDTO & OrganisationGetDTO>,
    file?: File | null,
  ): Promise<OrganisationGetDTO | null> {
    const formData = new FormData();
    formData.append('title', organisation.title || '');
    formData.append('domain', organisation.domain || '');
    if (organisation.vanityDomain) {
      formData.append('vanityDomain', organisation.vanityDomain);
    }
    if (file) {
      formData.append('file', file);
    }
    if(organisation.tenantId) {
      formData.append('tenantId', organisation.tenantId);
    }
    try {
      if (organisation.id) {
        const res = await this.repo.updateOrganisation(organisation.id, formData);
        return res.data;
      }
      return null;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      const businessException = error as ExceptionBase;
      if (businessException && businessException.code === ExceptionCode.Conflict) {
        throw new ConflictException(businessException.message);
      }
      return null;
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new OrganisationCreateManager(organisationCreateRepoInstance);
