import { HTTP_DEFAULT_CONFIG } from '@core/http-service';

export const BFF_ENDPOINTS = {
  ORGANISATION_GET: 'organisations',
  ORGANISATION_BY_TENANT_ID_GET: 'organisations/tenant/{tenantId}/organisation',
  ORGANISATION_POST: 'organisations/{organisationId}',
  ORGANISATION_BRANDS_GET: 'organisations/{organisationId}/brands',
  ORGANISATION_BRANDS_POST: 'organisations/{organisationId}/brands/{brandId}',
  ORGANISATION_UNITS_GET: 'organisations/{organisationId}/organisation-units',
  ORGANISATION_UNITS_POST: 'organisations/{organisationId}/organisation-units/{organisationUnitId}',
  ORGANISATION_TAGS: 'organisations/{organisationId}/tags',
  CHANNEL: '/organisations/{organisationId}/channels',
  ORGANISATION_CONTENT_STREAM: '/organisations/{organisationId}/content-stream',
  ORGANISATION_CONTENT_STREAM_CAMPAIGNS: '/organisations/{organisationId}/content-stream/campaigns',
  USER_ME: `${process.env['NX_USER_SERVICE_BASE_URL']}/me`,
  CAMPAIGNS: '/organisations/{organisationId}/campaigns',
  USER_DETAILS: '/users?email={userEmail}',
  LOCATIONS: '/locations?search={search}',
  ORGANISATION_QRCODES_GET: 'organisations/{organisationId}/qrcodetemplates',
  ORGANISATION_QRCODES_PUT: 'organisations/{organisationId}/qrcodetemplates/{templateId}',
  CONTENT: 'organisations/{organisationId}/contents',
  CONTENT_BY_ID: 'organisations/{organisationId}/contents?ids={contentIds}',
  LIST_CONTENTS: 'organisations/content/all',
  ORGANISATION_ANALYITCS_GET: 'organisations/{organisationId}/analytics',
};

export const USER_SERVICE_ENDPOINTS = {
  LOGIN_PATH: `${process.env['NX_USER_SERVICE_BASE_URL']}/me/login?returnTo={path}`,
};

export const CONFIG = {
  ...HTTP_DEFAULT_CONFIG,
  baseURL: process.env['NX_BACKOFFICE_BFF_BASE_URL'],
};
