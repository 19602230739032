/**
 * Use this to call BFF endpoints
 */

import {
  OrganisationGetDTO,
} from '@connected-core-system/utils/organisation-http-dto';
import { HttpService, HTTP_FORM_DATA_CONFIG } from '@core/http-service';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { IOrganisationCreateRepository } from '../types/IOrganisationCreateRepository';
import { interpolateWithValues } from '../../../shared/utils';
import { AxiosRequestConfig } from 'axios';

export class OrganisationCreateRepository implements IOrganisationCreateRepository {
  constructor(private readonly httpService: HttpService) {}

  createOrganisation(organisation: FormData) {
    const url = BFF_ENDPOINTS.ORGANISATION_GET;
    const config: AxiosRequestConfig = { ...HTTP_FORM_DATA_CONFIG };

    return this.httpService.post<OrganisationGetDTO>(url, organisation, config);
  }

  updateOrganisation(id: string, organisation: FormData) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_POST, id);
    const config: AxiosRequestConfig = { ...HTTP_FORM_DATA_CONFIG };

    return this.httpService.put<OrganisationGetDTO>(url, organisation, config);
  }
}

export default new OrganisationCreateRepository(HttpResolver.getHttpService());
