/* eslint-disable @typescript-eslint/no-empty-interface */
import { OrganisationGetDTO } from '@connected-core-system/utils/organisation-http-dto';
import { ConflictException, UniquePropertyExistsException } from '@core/exceptions';
import { FileItemType } from '@wppopen/components-library';
import { WppActionButton, WppButton } from '@wppopen/components-library-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxContent } from '../../../shared/components';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { translate } from '../../../shared/locales/i18n';
import { IOrganisation, MenuItemEnum } from '../../../shared/types';
import { isArrayEmpty } from '../../../shared/utils';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { useSelectedOrganisation } from '../../SideMenu';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import OrganisationCreateManager from '../services/OrganisationCreateManager';
import {
  FormType,
  IOrganisationCreateActions,
  IOrganisationCreateState,
} from '../sliceStore/organisationCreateSliceStore';
import { addOrganisationOrderedInList, replaceOrganisationInList } from '../utils';
import FormBody from './FormBody';
import { StyledWppFileUpload } from './styles/FileUpload.styled';
import { StyledModal } from './styles/FormInput.styled';
import { ModalActions, StyledWppProgressIndicator } from './styles/ModalButton.styled';

export interface IOrganisationFormProps {
  organisationList: IOrganisation[];
  setOrganisationList: (value: IOrganisation[]) => void;
}

const OrganisationForm: React.FC<IOrganisationFormProps> = ({ organisationList, setOrganisationList }) => {
  const navigate = useNavigate();
  const isModalOpened = useSliceState<IOrganisationCreateState, 'isModalOpened'>('isModalOpened');
  const organisationFormType = useSliceState<IOrganisationCreateState, 'organisationFormType'>('organisationFormType');
  const { selectedOrganisation, setSelectedOrganisation } = useSelectedOrganisation();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const isEditingMode = organisationFormType === FormType.edit && selectedOrganisation;
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    errorToastMessage: '',
  });
  const [closeModal, setCloseModal] = useState(false);
  const [title, setTitle] = useState(isEditingMode ? selectedOrganisation.title : '');
  const [domain, setDomain] = useState(isEditingMode ? selectedOrganisation.domain : '');
  const [vanityDomain, setVanityDomain] = useState(isEditingMode ? selectedOrganisation.vanityDomain : '');
  const [imageFile, setImageFile] = useState<File | null>(
    isEditingMode && selectedOrganisation?.fileName ? new File([], selectedOrganisation.fileName) : null,
  );
  const [imagePath, setImagePath] = useState<string | null>(
    isEditingMode && selectedOrganisation?.filePath ? selectedOrganisation?.filePath : null,
  );
  const [fileUploadValue] = useState<FileItemType[] | undefined>(
    isEditingMode && selectedOrganisation.filePath
      ? ([
          {
            url: selectedOrganisation.filePath,
            name: selectedOrganisation.fileName,
            size: selectedOrganisation.fileSize,
            type: selectedOrganisation.fileType,
          },
        ] as FileItemType[])
      : undefined,
  );
  const [tenantId, setTenantId] = useState(isEditingMode ? selectedOrganisation.tenantId : '');

  const setOpenModal = useSliceSetter<IOrganisationCreateActions, 'setOpenModal'>('setOpenModal');

  const formHeaderTitle = isEditingMode
    ? translate('txtEditOrganisation')
    : translate('txtCreateNewSomething', { something: translate('txtOrganisation') });
  const btnCancel = translate('btnCancel');
  const btnCreate = translate('btnCreate');
  const btnSave = translate('btnSave');
  const txtToastFeedback = isEditingMode
    ? translate('txtChangesSaved')
    : translate('txtSuccessCreated', { something: translate('txtOrganisation') });
  const txtErrorMessage = translate('txtErrorMessage');
  const txtDomainExistsMessage = translate('txtDomainExistsMessage');
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerHeight < 720);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerHeight < 720);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //this function forces rerender WppSelectComponent after creating new Organisation
    if (closeModal) {
      setCloseModal(false);
      setOpenModal(false);
    }
  }, [closeModal, setOpenModal]);

  const resetForm = () => {
    setTitle('');
    setDomain('');
    setVanityDomain('');
    setTenantId('');
    setImageFile(null);
    setImagePath(null);
    setOpenModal(false);
  };

  const handleCreateOrganisation = async () => {
    setPromise({
      loading: true,
      error: false,
      success: false,
      errorToastMessage: '',
    });
    let result: OrganisationGetDTO | null;
    const file = imagePath ? imageFile : null;
    try {
      if (isEditingMode) {
        result = await OrganisationCreateManager.updateOrganisation(
          { ...selectedOrganisation, title, domain, vanityDomain, tenantId },
          file,
        );
      } else {
        result = await OrganisationCreateManager.createOrganisation({ title, domain, vanityDomain, tenantId }, file);
      }
      if (result) {
        setPromise({
          loading: false,
          error: false,
          success: true,
          errorToastMessage: '',
        });
        const organisation = result as OrganisationGetDTO;
        if (organisation.filePath) {
          organisation.filePath = 'api/organisations/' + organisation.filePath;
        }
        if (isEditingMode) {
          setOrganisationList(replaceOrganisationInList(organisationList, organisation));
        } else {
          setOrganisationList(addOrganisationOrderedInList(organisationList, organisation));
        }
        setSelectedOrganisation(organisation);
        setSelectedSideMenuItem(MenuItemEnum.UNIT);
        navigate(`${organisation.id}/${MenuItemEnum.UNIT}`);
        setCloseModal(true);
      } else {
        setPromise({
          loading: false,
          error: true,
          success: false,
          errorToastMessage: txtErrorMessage,
        });
      }
    } catch (error) {
      let errorMessage: string;
      if (error instanceof UniquePropertyExistsException) {
        errorMessage = translate('txtTitleUsed');
      } else if (error instanceof ConflictException) {
        result = null;
        errorMessage = txtDomainExistsMessage;
      } else {
        errorMessage = txtErrorMessage;
      }
      setPromise({
        loading: false,
        error: true,
        success: false,
        errorToastMessage: errorMessage,
      });
    }
  };

  const handleFileUpload = async (event: CustomEvent) => {
    if (!isArrayEmpty(event.detail.value)) {
      const files = event.detail.value as Array<File>;
      const reader = new FileReader();
      const lastFile = files[files.length - 1];
      if (lastFile) {
        reader.readAsDataURL(lastFile);
        reader.onload = function () {
          setImageFile(lastFile);
          setImagePath(reader.result as string);
        };
      }
    } else {
      setImageFile(null);
      setImagePath(null);
    }
  };

  const isSaveButtonDisabled = () => {
    return !!(
      !title ||
      !domain ||
      domain.length > 20 ||
      (title === selectedOrganisation?.title &&
        domain === selectedOrganisation?.domain &&
        tenantId === selectedOrganisation?.tenantId &&
        (vanityDomain === selectedOrganisation?.vanityDomain || vanityDomain === '') &&
        ((!imageFile && !selectedOrganisation.filePath) ||
          (selectedOrganisation.filePath && imageFile && selectedOrganisation.filePath === imagePath)))
    );
  };

  return (
    <StyledModal open={isModalOpened} disableOutsideClick={true} size="m" isSmallScreen={isSmallScreen}>
      <h3 slot="header" style={isSmallScreen ? { margin: '0px' } : { margin: '24px 0px' }}>
        {formHeaderTitle}
      </h3>

      <FormBody
        isSmallScreen={isSmallScreen}
        title={title}
        domain={domain}
        organisationExternalDomain={vanityDomain}
        tenantId={tenantId}
        filePath={imagePath || undefined}
        setOrganisationTitle={setTitle}
        setOrganisationDomain={setDomain}
        setOrganisationExternalDomain={setVanityDomain}
        setOrganisationTenantId={setTenantId}
        isEditingMode={!!isEditingMode}
        fileUploader={
          <BoxContent marg="16 0 0 0" w="auto">
            {fileUploadValue && (
              <StyledWppFileUpload
                value={fileUploadValue}
                isSmallScreen={isSmallScreen}
                onWppChange={handleFileUpload}
                multiple={false}
                size={5}
                locales={{
                  formatError: translate('txtUploadFormatError'),
                  label: translate('txtChooseFile'),
                  text: translate('txtUploadHere'),
                  sizeError: translate('txtUploadSizeError'),
                  info: (accept: string, size: number) => translate('txtAcceptUpload'),
                }}
              />
            )}
            {!fileUploadValue && (
              <StyledWppFileUpload
                isSmallScreen={isSmallScreen}
                onWppChange={handleFileUpload}
                multiple={false}
                size={5}
                locales={{
                  formatError: translate('txtUploadFormatError'),
                  label: translate('txtChooseFile'),
                  text: translate('txtUploadHere'),
                  sizeError: translate('txtUploadSizeError'),
                  info: (accept: string, size: number) => translate('txtAcceptUpload'),
                }}
              />
            )}
          </BoxContent>
        }
      />
      <ModalActions slot="actions">
        <BoxContent flex justify="flex-end">
          <FallbackWrapper {...promise} successToastMessage={txtToastFeedback} />
          {(promise.loading || closeModal) && <StyledWppProgressIndicator variant="bar" />}

          {!promise.loading && !closeModal && (
            <BoxContent flex justify="flex-end" marg="16 0 0 0">
              <BoxContent w="auto" marg="0 12 0 0">
                <WppActionButton variant="secondary" onClick={resetForm}>
                  {btnCancel}
                </WppActionButton>
              </BoxContent>

              <BoxContent w="auto">
                <WppButton
                  data-testid="form-button"
                  variant="primary"
                  size="s"
                  disabled={isSaveButtonDisabled()}
                  onClick={() => handleCreateOrganisation()}>
                  {isEditingMode ? btnSave : btnCreate}
                </WppButton>
              </BoxContent>
            </BoxContent>
          )}
        </BoxContent>
      </ModalActions>
    </StyledModal>
  );
};

export default OrganisationForm;
